import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

// create a new component called Article 
// note it uses the Layout component (imported above)
const Article = ({ data }) => {
    // this is just to create a short version (post) of data.nodeArticle 
    const post = data.nodeArticle;
    // this is the actual content of the Article component
    return (
        <Layout>
            <h1>{post.title}</h1>
            <img src={post.relationships.field_image.localFile.publicURL} alt={post.field_image.alt} />
            <div dangerouslySetInnerHTML={{__html: post.body.processed}} />
        </Layout>
    );
};
// one thing to note in above is that this is OK but not ideal
// <img src={post.relationships.field_image.localFile.publicURL} alt={post.field_image.alt} />
// Gatsby has a special Img component that as built in lazy loading 

// this query is getting the content for this article
// if only needs the article id that got passed in via the query 
// which ran in gatsby-node.js that created each page (article) 
//
// in this line: nodeArticle(id: { eq: $ArticleId }) {
// it is using ArticleId that was passed in from the following portion of the query 
// in createPage in gatsby-node.js
//  context: {
//      ArticleId: articleData.id,
//  },
       
export const query = graphql`
    query($ArticleId: String!) {
        nodeArticle(id: { eq: $ArticleId }) {
            body {
                processed
            }
            title
            field_image {
                alt
            }
            relationships {
                field_image {
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
`;

// in React you alwasy have to export the component in order to use it elsewhere 
export default Article;
